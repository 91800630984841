/* MyValues.css */
/* begin page */

.my-value-main{
    background-image: url('../../siteImages/my-value-shape-left.svg'), url('../../siteImages/my-value-shape-right.svg');
    background-repeat: no-repeat;
    background-position: left -50px, right 90px;
    background-size: 30%;
}
.my-value-main-inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 600px;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  /* adjust this value to change the space between elements */
  height: 100%;
  /* fill the available height */
  width: 100%;
  /* fill the available width */
  max-width: 60%;
  /* Adjust this value to set the maximum width of the content */
  margin-left: 35%;
  /* adjust this value to change the left padding */
  padding-top: 10%;
  padding-bottom: 10%;
}

.QuizTitle {
  /* remove position, left, and top properties */
  width: 400px;
  height: 112px;
  font-family: 'ClashGrotesk-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 56px;
  font-feature-settings: 'ss01' on;
  color: #0C3A25;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.QuizIntro {
  /* remove position, left, and top properties */
  width: 298px;
  height: 72px;
  transform: scale(1, 1);
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  line-height: 128%;
  text-align: left;
  vertical-align: top;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #0C3A25;
  direction: initial;
  text-align: right;
}




/* question detail pages */

.ContentContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 200px;
  gap: 30px;
  /* adjust this value to change the space between elements */
  height: 100%;
  /* fill the available height */
  width: 100%;
  /* fill the available width */
  max-width: 60%;
  margin-left: 35%;
  /* adjust this value to change the left padding */
  padding-top: 2%;
  padding-bottom: 10%;
}

.question-number-circle {
  position: absolute;
  top: 18%;
  /* Adjust the position from the top */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0C3A25;
  border-radius: 50%;
  width: 77px;
  /* Adjust the circle size */
  height: 77px;
  /* Adjust the circle size */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "ClashGrotesk-Regular", sans-serif;
  font-size: 37px;
  /* Adjust the font size */
}


.imp {
  /* HOW IMPORTANT IS ... */
  width: 276px;
  height: 16px;
  left: 406px;
  top: 328px;

  /* Eyebrow/2 */
  font-family: 'ClashGrotesk-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;

  padding-top: 13%;
  padding-left: 32%;
  padding-bottom: 4%;
  /* Greens/Folate Green */

  color: #0C3A25;
}

.QuestionText {
  /* HOW IMPORTANT IS ... */
  width: 600px;
  height: 35px;
  left: 406px;
  top: 328px;

  /* Eyebrow/2 */
  font-family: 'ClashGrotesk-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  /* identical to box height, or 100% */

  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;

  /* padding-top:8%; */
  padding-left: 32%;
  padding-bottom: 2%;
  /* Greens/Folate Green */

  color: #0C3A25;
}

.drag {
  position: relative;
  left: 11%;
  /* move the element to the middle horizontally */
  /* remove position, left, and top properties */
  width: 300px;
  height: 20px;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  line-height: 128%;
  text-align: left;
  vertical-align: top;
  /* padding-top: 16px; */
  color: #0C3A25;
}

.Begin {
  background-color: #0C3A25;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Begin */
  width: 186px;
  height: 40px;

  /* Documentation/Basic Buttons */

  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-feature-settings: 'ss01' on;

  /* Neutrals/White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.pre-next-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
}
.pre-next-container button {
    background: transparent;
    border: 0px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
}
.pre-next-container button span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DFF0D8;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-bottom: 10px;
}
.pre-next-container button:hover span{
    background: #0C3A25;
}
.pre-next-container button:hover span svg path{
    fill: #fff;
}
.Previous {
  background-color: #0C3A25;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Begin */
  width: 186px;
  height: 40px;

  /* Documentation/Basic Buttons */

  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-feature-settings: 'ss01' on;

  /* Neutrals/White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.Next {
  background-color: #0C3A25;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Begin */
  width: 186px;
  height: 40px;

  /* Documentation/Basic Buttons */

  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-feature-settings: 'ss01' on;

  /* Neutrals/White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.Begin:hover,
.Previous:hover,
.Next:hover {
  background-color: #aaa;
}



/* result page */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 7%;
  padding-bottom: 10%;
}

.result-title {
  font-family: 'ClashGrotesk-Regular', sans-serif;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 100px;
  color: #0C3A25;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  width: 100%;
  /* max-width: 1500px; */
  padding-bottom: 0%;
}

.summary-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  /* border-radius: 5px; */
}

.summary-box.choice-ques {
    width: 100%;
    text-align: center;
    background: #b9bfaf;
    margin-bottom: 5rem;
}

.summary-box.choice-ques.stuff-value {
    margin: 0;
}

.most-important {
  background-color: #DFF0D8;
}

.important {
  background-color: #ffffff;
}

.less-important {
  background-color: #FAF6ED;
}

.least-important {
  background-color: #E6D1BF;
}

.summary-title {
  font-family: 'ClashGrotesk-Regular', sans-serif;
  font-size: 24px;
  font-weight: bold;

  justify-content: center;

  margin-bottom: 10px;
  padding-top: 2%;
  padding-bottom: 5%;
  color: #0C3A25;
}

.summary-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  display: flex;
  /* Add this line to display elements side by side */
  /* justify-content: center;  */

  max-width: 400px;
}

.bottom-section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px;
  width: 100%;
  max-width: 800px; */
  gap: 300px;
}

.paragraph-container {
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  width: 100%;
  /* padding-right: 60%; */
}

.bottom-paragraph {
  font-size: 16px;
  line-height: 1.5;
  max-width: 300px;
}

.download-btn {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #0C3A25;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
}

.download-btn:hover {
  background-color: #333;
}
/*.css-xdmu94-MuiContainer-root {
    max-width: 567px;
}*/
.question-id {
    background-color: #0C3A25;
    padding: 16px;
    border-radius: 100px;
    color: #ffffff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
    margin: 0 auto 50px;
    font-family: ClashGrotesk-Medium;
}
.step-slider {
    max-width: 400px;
    margin: 40px auto 40px;
    width: 100%;
    display: block;
}
.heading5 {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Public Sans';
    letter-spacing: 0;
}
.heading3{
   font-size: 30px;
   line-height: 32px;
   color:#0C3A25;
   font-weight: 500;
   font-family: 'Public Sans';
}
.step-slider .MuiSlider-thumb {
    width: 37px;
    height: 37px;
    background-color: #0C3A25;
    border: 1px solid #000000;
    box-shadow: 5px 7px 17px 1px rgb(0 0 0 / 26%);
}
.step-slider .MuiSlider-rail{
  border-radius: 0px;
}
.step-slider .MuiSlider-markLabel {
    max-width: 75px;
    display: block;
    /* white-space: pre-wrap; */
    text-align: center;
    font-family: 'Public Sans';
    top: 50px;
}
.last-question .MuiSlider-markLabel {
  white-space: pre-wrap;
}
.drag-text{
    margin-top: 85px;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 400;
    font-family: 'Public Sans';
    font-size: 12px;
}
.nav-step-slider {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.nav-step-slider .MuiSlider-thumb,
.nav-step-slider  .MuiSlider-track,
.nav-step-slider .MuiSlider-rail {
    display: none;
}
.nav-step-slider .MuiSlider-mark {
    width: 100%;
    border-radius: 10px;
    height: 5px;
    position: initial;
    display: inline-block;
    margin-right: 20px;
    background-color: transparent;
    box-shadow: 0px 0px 4px 0px #00000025;
}
.nav-step-slider .MuiSlider-mark.MuiSlider-markActive{
    background-color: #0C3A25;
    box-shadow:none;
}
.back_btn {
    width: 100%;
    margin-top: 50px;
    text-align: center;
}
.back_btn a {
    color: #0C3A25;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Public Sans';
    padding-bottom: 0px;
    border-bottom: 1px solid #0C3A25;
    text-decoration: none;
}
.progress-bar .MuiStepConnector-horizontal {
    display: none;
}
.progress-bar .MuiStep-horizontal {
    margin-right: 20px;
    width: 100%;
    padding: 0px;
}
.progress-bar .MuiStepLabel-iconContainer{
    height: 5px;
    width:100%;
    border-radius: 10px;
    background: transparent;
    box-shadow: 0px 0px 4px 0px #00000025;
}
.progress-bar .MuiStepLabel-iconContainer svg,
.progress-bar .MuiStepLabel-labelContainer{
    display: none;
}
.progress-bar .MuiStepLabel-iconContainer.Mui-active,
.progress-bar .MuiStepLabel-iconContainer.Mui-completed {
    background: #0C3A25;
}
.progress-bar .MuiStepConnector-horizontal .MuiStepConnector-line {  
    border-color: transparent;
    box-shadow: 0px 0px 4px 0px #00000025;
    border-top-width: 5px;
    border-radius: 10px;
}

.progress-bar .step {
    height: 5px;
    width: 85px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px #00000025;
    font-size: 0;
    margin-left:15px;
    position: relative;
}
.progress-bar .step:last-child {
    margin-left:0px;
}
.progress-bar {
    display: flex;
    justify-content: center;
}
.progress-bar .step:before {
    content: "";
    background: #0C3A25;
    height: 5px;
    position: absolute;
    right: 0;
    width: 0%;
    display: block;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
}
.progress-bar .step.active:before {
    width: 100%;
}

.hero-results-section{
    background-image: url('../../siteImages/hero-bg-result.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
}

.view-question-bottom-img{
    width: 100%;
}

.summary-box .icon-shape{
    background: #F4FCF0;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.4rem;
}


.step-slider.step-slider-v2 {
    max-width: 540px;
    margin: 40px auto 140px;
}

.step-slider.step-slider-v2 .MuiSlider-rail {
    width: 100%;
}

.step-slider.step-slider-v2 span.MuiSlider-markLabel {
    max-width: 110px;
    width: 110px;
}

@media(max-width:767px){
    .summary-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(max-width:600px){
    .my-value-main{
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-image: none;
        height: calc(100vh - 88px);
        min-height: 610px;
    }
    .step-slider {
        max-width: 100%;
        width: 80%;
    }
    .step-slider .MuiSlider-rail {
        width: 100%;
    }
    .MuiTypography-h5{
        margin-bottom: 0.5rem;
    }
    .step-slider .MuiSlider-thumb {
        width: 30px;
        height: 30px;
    }
    .my-value-content {
        padding: 100px 40px;
    }
    .my-value-content p.QuizIntro {
        width: 100%;
    }
    .step-slider.step-slider-v2 {
        width: 90%;
        max-width: 100%;
    }
    .step-slider.step-slider-v2 span.MuiSlider-markLabel {
        max-width: 60px;
        width: 60px;
        font-size: 11px;
    }
}
