@media screen and (max-width: 700px) {
    .header .nav-list{
        flex-direction: column;
        justify-content: space-between;
        min-height: 30vh;
    }
  
    .header.responsive {
      display: flex;
      flex-direction: column;
    }

    .header .language-selector{
        justify-content: center;
        margin-top: 40px;
    }

    .header .responsive {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .header .responsive .nav-log .nav{
      width: 100%;
    }
  }

  .nav{
    width: 180px;
    height: auto;
  }

.header .nav-list button{
    margin: 0 10px;    
}

.language-selector .MuiSelect-select {
    background: #F2F2F2;
    border: none !important;
    border-radius: 40px !important;
    padding: 12px 20px;
    width: 100px;
    color: #0C3A25;
}

.language-selector fieldset {
    border: none;
}

.toggle-drawer .MuiDrawer-paper {
    width: 100%;
    padding: 3rem 2rem 2rem 2rem;
    box-sizing: border-box;
}

.toggle-drawer .menu-list {
    border-top: 1px solid #D8D8D8;
    padding: 10px 0;
}

.toggle-drawer .menu-list:first-child {
    border-top: 0;
}
.toggle-drawer .menu-list button {
    width: 100%;
    border-radius: 10px;
    display: block;
    text-align: left;
    padding: 17px 17px;
    font-size: 16px;
    color: #0C3A25;
    border: none;
}

.toggle-drawer .menu-list button.active-btn {
    background-color: #DFF0D8;
    background-image: url('../../siteImages/icons/check-icon.svg');
    background-position: 94% center;
    background-repeat: no-repeat;
    background-size: 22px;
}

.cross-icon {
    position: absolute;
    top: 20px;
    right: 25px;
}


.language-menu li {
    display: inline-block;
}

.language-menu li a {
    color: #0C3A25 !important;
    border: 1px solid #0C3A25;
    border-radius: 20px;
    padding: 8px 13px;
    text-decoration: none;
    margin: 0 10px 10px 0 !important;
    display: inline-block;
}

.language-menu li a:hover{
    background-color: #0C3A25;
    color: #ffffff !important;
}

header .menu-btn {
    color: #0C3A25 !important;
    border: 1px solid #0C3A25;
    border-radius: 20px;
    padding: 5px 16px;
    margin: 0 !important;
    min-width: 154px;
    text-align: left;
    display: flex;
    text-transform: capitalize;
    font-family: ClashGrotesk-Medium;
    font-size: 1rem;
    justify-content: space-between;
}


@media (max-width: 767px){
    .nav-logo {
        width: 110px;
    }
    .nav-logo img.nav {
        width: 100%;
    }
    .language-selector {
        border-top: 1px solid #B3B3B3;
        padding-top: 20px;
    }
}
