div.allFooter {
  background-color: #0c3a25;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

#srt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 5vw;
  margin-left: 115px;
  padding: 2vw 2vw;
  border-radius: 25px;
  border: none;
  background-color: #fff;
  height: 1.5vw;
  flex-grow: 0;
  font-family: Public Sans;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0c3a25;
}

.footer-title {
  color: #dff0d8;
}

img.logo {
  width: 300px;
  height: auto;
}
button.allFooter {
  width: 224px;
  flex-grow: 0;
  margin: 10px 10px 9px 0px;
  font-family: Public Sans;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  display: inline-block;
}

button.leftFooter {
  width: 224px;
  flex-grow: 0;
  margin: 10px 10px 9px 115px;
  font-family: Public Sans;
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  display: inline-block;
}

#col1F {
  float: left;
  width: 40%;
}
#col2F {
  float: left;
  width: 30%;
}
#col3F {
  float: right;
  width: 30%;
}

ol {
  list-style: none;
}

li {
  line-height: 1.5vws;
}

hr {
  margin-top: 3vw;
  margin-bottom: 0;
  padding: 0;
}


.allFooter ul.footer-menu li a{
    font-family: Public Sans;
    font-size: 1rem;
}

.white-btn{
  background: white; 
  font-size: 18px;
  border-radius: 30px; 
  padding: 0.7rem 1.3rem; 
  text-transform: inherit; 
  text-decoration: none;
  font-family: Public Sans;
  margin-top: 1rem;
}

.footer-divider{
  margin-bottom: 20px !important;
}

button:hover a {
    color: inherit;
}
.hide-for-desktop{
  display: none;
}


.cookie-banner .MuiBackdrop-root {
    display: none;
}

.cookie-banner .MuiDialog-container {
    height: auto;
}

.cookie-banner {
    top: auto !important;
}

.cookie-banner .MuiDialog-container>.MuiPaper-root {
    max-width: 100%;
    width: 100%;
    margin: 20px 20px;
}

.cookie-banner .MuiDialog-container>.MuiPaper-root .MuiDialogContent-root {
    display: flex;
    align-items: center;
}

.cookie-banner .MuiDialog-container>.MuiPaper-root .MuiDialogContent-root p {
    font-size: 19px;
    margin: 0;
    line-height: normal;
}

.cookie-banner .MuiDialog-container>.MuiPaper-root .MuiDialogContent-root button {
    margin-left: auto;
    min-width: 70px;
}

@media(max-width:1024px){
img.logo {
    width: 100%;
    margin-bottom: 20px;
}
div.allFooter {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.footer-title {
    font-size: 1.3rem;
}
.white-btn {
    display: inline-block;
}

}

@media(max-width:767px){
    .footer-title {
        font-size: 1.1rem;
    }
    img.logo {
        width: 200px;
        margin-bottom: 20px;
    }
    .MuiContainer-root {
        padding-left: 25px;
        padding-right: 25px;
    }
    .allFooterInner {
        margin: 0px !important;
        display: block !important;
        width: 100% !important;
        padding: 0px 10px;
    }
    .Footercol-blank {
        display: none;
    }
    .Footercol {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-top: 0px !important;
        margin-bottom: 15px !important;
    }
    .Footercol:last-child {
        margin-bottom: 0px !important;
    }
    .allFooter ul.footer-menu {
        padding: 0px;
    }
    .allFooter ul.footer-menu li {
        line-height: normal;
        display: block;
        height: auto;
        min-height: auto;
        margin-bottom: 15px;
    }
    .allFooter ul.footer-menu li:last-child {
        margin-bottom: 0px;
    }

    .allFooter ul.footer-menu li a {
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        white-space: normal;
    }
    .white-btn {
        display: inline-block;
        text-decoration: none !important;
        padding: 0.8rem 1.5rem;
        margin-top: 25px !important;
        margin-bottom: 15px !important;
    }
    .footer-title {
        margin-bottom: 15px;
        -webkit-font-smoothing: antialiased;
    }
    .Footercol-half {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #fff;
        padding-bottom: 20px;
    }
    .hide-for-mobile{
        display: none;
    }
    .hide-for-desktop {
        display: block;
    }
    .Footercol-half hr{
        display: none;
    }
    .Footercol-half-left,
    .Footercol-half-right {
        width: 46%;
    }
    div.allFooter {
        padding-top: 4rem;
        padding-bottom: 8rem;
    }
    .support-btn-link {
        display: inline-block;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        text-decoration: none !important;
    }
}



