.compare-your-choices {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #0c3a25;
  font-size: 56px;
  line-height: 56px;
  font-weight: 500;
  text-align: center;
}

.subTitle {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #0c3a25;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
}

.four-tags-subtitle {
  font-family: "Public Sans";
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.vector {
  border: 1px solid #dff0d8;
}

.weeks-number {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  color: #0c3a25;
}

.weeks {
  font-family: "Clash Grotesk Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  padding-top: 8px;
  color: #0c3a25;
}

.Timing-circles {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dff0d8;
}

.Labor-circles {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0c3a25;
}

.days-number {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  color: #dff0d8;
}

.days {
  font-family: "Clash Grotesk Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  padding-top: 8px;
  color: #dff0d8;
}

.experience-7 {
  font-family: ClashGrotesk-Medium;
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #000000;
  font-weight: 500;
  margin-right: 5px;
}

.pain-percent-circlesContent {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #000000;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
}

.chance {
  font-family: "Clash Grotesk Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.25px;
  color: #000000;
}

.no-difference {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.same-rectangle {
  background: #F4FCF0;
  border-radius: 12px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 19px;
  padding-right: 23px;
}

.same-for-all-opts {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.same-breastfeed {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00653E;
}

.potential-risks {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  color: #A86133;
}

.potential-risks-6 {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.5px;
  padding-left: 4px;
  padding-top: 20px;
  color: #A86133;
}

.potential-risks-subtitle {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.view-risks-accordion-main {
    box-shadow: 0 0;
}

.view-risks-accordion {
  background-color: #A86133;
  border-radius: 12px;
  width: 100%;
  height: 48px;
}

.view-risks-title {
  color: #FFFFFF;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
}

.expandIcon {
  color: #FFFFFF;
}

.learn-choices {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #0c3a25;
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  text-align: center;
}

.learn-choices-subTitle {
  font-family: ClashGrotesk-Medium;
  font-style: normal;
  font-feature-settings: "ss01" on;
  color: #0c3a25;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
}

.learn-more-button {
    font-family: "Public Sans";
    font-style: normal;
    font-feature-settings: "ss01" on;
    border: 1px solid #0c3a25;
    border-radius: 45px;
    width: auto;
    background-color: #FFFFFF;
    color: #0c3a25;
    font-weight: 400;
    font-size: 16px;
    line-height: inherit;
    display: inline-block;
    text-decoration: none;
    padding: 12px 18px;
}

.sources-accordion {
  height: 52px;
}

.sources {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-feature-settings: "ss01" on;
  font-weight: 450;
  font-size: 24px;
  line-height: 31px;
  color: #0c3a25;
}

.need-help {
  background-color: #FAF6ED;
  width: 1300px;
  height: 533px;
}

#william-image {
  margin-top: 50px;
  border-radius: 1115px;
  width: 263px;
  height: 411px;
}

.need-help-choosing {
  font-family: ClashGrotesk-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  color: #0c3a25;
}

.find-out-button {
  background-color: #0c3a25;
  width: 120px;
  height: 40px;
  border-radius: 25px;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-feature-settings: "ss01" on;
  color: #FFFFFF;
  margin-top: 25px;
  border:0px;
  cursor: pointer;
}


.w-full {
    width: 100%;
}

.w-max-full {
    max-width: 100%;
}

.choice-sticky-bar {
    background: #fff;
    box-shadow: 0 0;
    padding: 20px 0;
}
.choice-sticky-bar.sticky-appbar {
    background: #E7F4E2;
    box-shadow: 0px 2px 7px -4px rgba(0,0,0,0.2);
}


.compare-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 0 13px;
    max-width: 400px;
}

.compare-section button.compare-btn {
    background: #F4FCF0;
    border-radius: 20px;
    width: calc(100% - 40px);
    display: block;
    text-align: left;
    padding: 10px 16px;
    color: #0C3A25;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.compare-section .vs {
    background: #0C3A25;
    color: #fff;
    font-family: Public Sans;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    min-width: 40px;
}

.compare-section button.compare-btn-choice {
    border: 1px solid #B3B3B3;
    border-radius: 20px;
    padding: 5px 28px 5px 16px;
    margin: 0 !important;
    width: calc(100% - 40px);
    text-align: left;
    display: block;
    text-transform: inherit;
    font-family: ClashGrotesk-Medium;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.compare-section button.compare-btn-choice svg {
    position: absolute;
    right: 8px;
    top: 8px;
}

.choice-drawer .MuiDrawer-paper {
    width: 100%;
    padding: 1.5rem 2rem 1.5rem 2rem;
    box-sizing: border-box;
    border-radius: 40px 40px 0px 0;
}

.choice-drawer .choice-nav {
    border-top: 1px solid #D8D8D8;
    padding: 10px 0;
}

.choice-drawer .choice-nav:first-child {
    border-top: 0 none;
}

.choice-drawer .choice-nav label.choice-label {
    width: 100%;
    display: block;
    margin: 0;
    position: relative;
}

.choice-drawer .choice-nav label.choice-label .choice-checkbox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.choice-drawer .choice-nav label .MuiFormControlLabel-label {
    width: 100%;
    border-radius: 10px;
    display: block;
    text-align: left;
    padding: 20px 17px;
    font-size: 16px;
    color: #0C3A25;
    border: none;
    box-sizing: border-box;
}

.choice-drawer .choice-nav label.choice-label .choice-checkbox.Mui-checked + .MuiFormControlLabel-label {
    background-color: #DFF0D8;
    background-image: url('../../siteImages/icons/check-icon.svg');
    background-position: 94% center;
    background-repeat: no-repeat;
    background-size: 18px;
}

.back-top-btn {
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: underline !important;
}

.compare-popup-toggle {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    display: flex;
}

.compare-popup-toggle svg {
    color: #A86133;
    margin-right: 12px;
}

a.link-text {
    color: #000000;
    font-family: Public Sans;
    border-bottom: 2px solid #000;
    text-decoration: none;
    line-height: initial;
}

.compare-sticky-bar.sticky-appbar {
    box-shadow: 0px 3px 12px rgb(0 0 0 / 17%);
}

@media(max-width:767px){
  .TimeBlock {
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: initial !important;
      margin-top: 3rem;
  }
  .TimeBlock-content {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
  }
  .TimeBlock-content>.MuiGrid-item {
      width: 38%;
      flex-basis: initial;
      max-width: 100%;
  }
  .TimeBlock-content>.MuiGrid-item img{
    width: 100%;
  }
  .same-rectangle {
      background: #F4FCF0;
      border-radius: 12px;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-left: 25px;
      padding-right: 25px;
      text-align: center;
  }
  .quote-box,
  .Risks-section {
      margin-top: 3rem;
  }
  .Risks-section .MuiTypography-h5{
    color: #A86133;
  }
  .view-risks-accordion {
      width: 100%;
      height: 48px;
  }
  .view-risks-accordion-main{
    box-shadow: none;
  }
  .view-risks-accordion-main .MuiCollapse-vertical{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 13px;
  }
  .view-risks-details .MuiGrid-container {
/*      margin-top: 1rem !important;*/
/*      flex-basis: 100% !important;*/
/*      max-width: 100% !important;*/
  }
  .view-risks-details>.MuiGrid-container {
      margin-top: 0px !important;
  }
  .FourTagsStyle {
      font-size: 24px;
      line-height: 30px;
  }
  .for-all-rectangle {
      padding: 2.5rem;
  }
  .for-all-rectangle-col{
      width: 100%;
      max-width: 100% !important;
      flex-basis: 100% !important;
  }
  .css-1h5hrnu-MuiGrid-root {
      margin-top: 3rem;
  }
  .need-help {
      background-color: #FAF6ED;
      width: 100%;
      height: auto;
      margin-top: 3rem;
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
      row-gap: 10px;
      padding: 30px 25px;
      align-items: center;
  }
  #william-image {
      margin-top: 0px !important;
      border-radius: 1115px;
      width: 131px;
      height: 204px;
  }
  .william-image {
      height: auto;
      width: 100%;
      text-align: center;
  }
  .william-image-content {
      margin-top: 0px;
      margin-left: 0px;
      height: auto;
      width: 100%;
  }
  .need-help-choosing {
      font-size: 24px;
      font-size: 24px;
      line-height: initial;
      padding-bottom: 0;
  }
  .need-help-choosing-text {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      margin-top: 8px;
  }
  .find-out-button {
      height: 48px;
      margin-top: 15px;
  }
  .source-section {
      margin-top: 3rem;
      max-width: 100%;
      flex-basis: initial;
  }
  .panel1a-header{
      padding-left:0px;
      padding-right:0px;
  }
  .mobile-font-24{
      font-size: 24px;
  }
  .learn-choices-subTitle{
      font-size:16px;
      margin-bottom: 20px;
  }
  .potential-risks-small-content{
      font-size: 14px;
      line-height: 21px;
  }
  .experience-body-content{
      text-align: center;
  }
}

