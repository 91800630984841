.question-outer{
	position: relative;
}
.question-outer .MuiCheckbox-root {
    position: absolute;
    top: 20px;
    right: 18px;
    padding: 0;
    z-index: 9;
    border-radius: 100px;
    height: 44px;
    width: 44px;
    border: 2px solid #DFF0D8;
}
.question-outer .MuiCheckbox-root.Mui-checked {
    background-color: #DFF0D8;
    border-color: #DFF0D8;
    background-image: url(../../siteImages/icons/check-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
}
.question-outer .MuiCheckbox-root svg.MuiSvgIcon-root{
    display: none;
}
.question {
    background: white;
    padding: 33px 78px 23px 22px;
    border-radius: 10px;
    position: relative;
    font-family: ClashGrotesk-Medium;
    font-size: 16px;
    min-height: 27px;
    display: flex;
    align-items: flex-start;
}
.question.no-flex {
    display: block;
}
.question.pdf-ques{
    padding: 14px 22px 14px 28px;
    display: block;
    color: #0C3A25;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: ClashGrotesk-Medium;
}

.tooltop-pdf h4{
    font-family: "Public Sans";
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    margin-bottom: 5px;
}
.tooltop-pdf p{
    color: #0C3A25;
    font-size: 1.15rem;
    font-family: "Public Sans";
}

.question-outer .MuiCheckbox-root.Mui-checked + .question {
    background: #F4FCF0;
}
/*.question-outer .MuiCheckbox-root.Mui-checked + .question button.edit-btn {
    display: none;
}*/
.checklist-outer button.add-ques-btn {
    background: #DFF0D8;
    border: none;
    padding: 24px 10px;
    border-radius: 10px;
    width: 100%;
    color: #0C3A25;
    cursor: pointer;
}
.checklist-outer button.add-ques-btn img {
    vertical-align: middle;
    margin-right: 16px;
    position: relative;
    top: -2px;
}
.question button.edit-btn {
    position: absolute;
    left: 20px;
    border: none;
    background: transparent;
    padding: 0;
    opacity: 0;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    top: 26px;
}
.question:hover button.edit-btn{
	opacity: 1;
}
.question input[type="text"] {
    border: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    width: 100%;
    box-shadow: 0 0;
    outline: none;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.edit-case-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 10px;
}
.edit-case-btn-group button.delete-btn {
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    color: #00653E;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}
.edit-case-btn-group button.cancel-btn {
    border: 1px solid #0C3A25;
    background: transparent;
    border-radius: 22px;
    display: block;
    padding: 10px 21px;
    color: #0C3A25;
    cursor: pointer;
}
.edit-case-btn-group button.save-btn {
    border: 1px solid #0C3A25;
    background: #0C3A25;
    border-radius: 22px;
    display: block;
    padding: 10px 21px;
    color: #fff;
    cursor: pointer;
}
.MuiButtonBase-root.save-ques-btn {
    border-radius: 100px;
    text-transform: capitalize;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 11px;
    font-size: 1.125rem;
}
.MuiButtonBase-root.save-ques-btn img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 6px;
    width: 23px;
}
.question .tooltip-icon {
    margin-left: 50px;
    margin-right: auto;
    cursor: pointer;
}

.MuiTooltip-tooltip p.MuiTypography-root {
    color: #666666;
    font-size: 14px;
    margin-bottom: 6px;
    line-height: initial;
    height: auto;
}
.MuiTooltip-tooltip a {
    color: #00653E;
    font-size: 14px;
    font-weight: bold;
}

.MuiTooltip-tooltip{
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    max-width: 220px;
    font-size: 12px;
    border-radius: 0px;
    padding: 20px 20px 40px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top: 4px !important;
}

.loading-screem {
    position: fixed;
    top: 0px;
    bottom: 0px;
    background: rgb(255 255 255 / 86%);
    left: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-screem img.logo-icon {
    margin-bottom: 20px;
}
.loading-screem h4 {
    color: #0C3A25;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: ClashGrotesk-Medium;
}
.tabs-head button {
    font-family: ClashGrotesk-Medium;
    font-size: 24px;
    text-transform: capitalize;
    padding: 0;
    margin: 0 0 0 20px;
    max-width: initial !important;
}

.value-summmary-card {
    padding: 2rem 3rem;
    box-shadow: 2px 7px 12px rgb(0 0 0 / 7%);
    border-radius: 12px;
}

.value-summmary-card .icon-shape{
    background: #F4FCF0;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.4rem;
}

.value-summmary-card p{
    line-height: 20px;
}

.MuiSnackbarContent-root {
    background: #0c3a25;
    font-family: ClashGrotesk-Medium;
}

.stuff-email-form .MuiDialog-container .MuiDialog-paperWidthSm {
    max-width: 500px;
    width: 100%;
}

#pdf-page .summary-box {
    padding: 20px 10px;
}

#pdf-page .summary-box h3 {
    font-size: 20px;
    margin: 0;
}

@media(max-width:767px){
    .value-summmary-card {
        padding: 25px 25px;
    }
    .box-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .value-summmary-card p {
        font-size: 14px;
    }
    .question .tooltip-icon {
        margin-left: 35px;
        margin-right: auto;
        padding-left: 10px;
    }
    .question button.edit-btn {
        width: 28px;
        top: 22px;
        opacity: 1;
    }
    .question button.edit-btn img {
        width: 100%;
    }
    .question {
        padding: 23px 78px 23px 22px;
    }
    .question span {
        max-width: 200px;
        width: 100%;
    }
    #pdf-page .summary-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    #pdf-page .nav-logo {
        width: 180px;
        margin: 0 auto 2rem;
    }
    #pdf-page .nav-logo img.nav {
        width: auto;
    }
}

