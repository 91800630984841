
.labor-block-title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.labor-acc-text {
    line-height: 24px;
    margin-bottom: 1.4rem;
}

.labor-block-desc{
    line-height: 24px;
}

.content-block-img{
    max-width: 100%;
    width: 100%;
}

.sidebar-sticky{
    position: sticky;
    top: 0;
}

.secondTitle.title-labor {
    font-size: 2.5rem;
}

@media (max-width: 767px){
    .secondTitle.title-labor {
        font-size: 2rem;
    }
    .labor-block-title{
        font-size: 1.5rem;
    }
    img#first-image {
        width: 100%;
        margin-top: 30px;
        border-radius: 141.88px;
        height: 257px;
    }
}